<template>
  <div class="social-active-achievements-add">
    <div
      class="social-active-achievements-add__toggle"
      @click="deleteAchievement"
    >
      Удалить достижение
    </div>
    <div class="social-active-achievements-add__body">
      <div class="social-active-achievements-add__body__input">
        <p class="social-active-achievements-add__description">
          Согласно
          <router-link
            :to="{ name: 'EventPage', params: { id: 1 } }"
            target="_blank"
          >
            Положению Конкурса,
          </router-link>
          &nbsp;сканы достижений необходимо загружать за
          {{ statementYear }} года
        </p>
        <p class="social-active-achievements-add__description">
          Все документы необходимо загружать с персонального компьютера или
          ноутбука во избежание технических неполадок
        </p>
        <div class="social-active-achievements-add__btns">
          <Button
            :secondary="typeAddition !== 'exist'"
            @click="setTypeAddition('exist')"
            v-if="formattedAchievementsList?.length > 0"
          >
            Добавить существующее
          </Button>
          <Button
            v-if="formattedAchievementsList?.length > 0"
            :secondary="
              typeAddition !== 'new' && formattedAchievementsList?.length !== 0
            "
            @click="setTypeAddition('new')"
          >
            Добавить новое
          </Button>
        </div>
        <Dropdown
          v-if="typeAddition === 'exist'"
          header="Достижение"
          placeholder="Выберите из списка"
          :keys="
            this.formattedAchievementsList?.map((item) => {
              return item.title_dropdown;
            })
          "
          :items="
            this.formattedAchievementsList?.map((item) => {
              return item.id;
            })
          "
          v-model="formData.achievement"
          @update:modelValue="setAchievement"
        />
        <!--          :error="v$.formData.achievement?.$errors?.length !== 0"-->
        <PortfolioForm
          v-if="typeAddition === 'new'"
          project
          @formFilled="setPortfolioData"
        />
        <Dropdown
          header="Область деятельности"
          placeholder="Выберите из списка"
          :disabled="
            typeAddition === 'exist'
              ? !formData.achievement
              : !portfolioData.title
          "
          :keys="
            this.currentArrays.activityField?.map((item) => {
              return item.title;
            })
          "
          :items="
            this.currentArrays.activityField?.map((item) => {
              return item.id;
            })
          "
          v-model="formData.activityField"
          :error="v$.formData.activityField?.$errors?.length !== 0"
          @update:modelValue="
            (value) => {
              setModelValue(
                this.formData?.activityField,
                'activityField',
                'criterion',
                value
              );
            }
          "
        />
        <Dropdown
          header="Критерий"
          placeholder="Выберите из списка"
          :disabled="!formData.activityField"
          :keys="
            this.currentArrays.criterion?.map((item) => {
              return item.title;
            })
          "
          :items="
            this.currentArrays.criterion?.map((item) => {
              return item.id;
            })
          "
          v-model="formData.criterion"
          :error="v$.formData.criterion?.$errors?.length !== 0"
          @update:modelValue="
            (value) => {
              setModelValue(
                this.formData?.criterion,
                'criterion',
                'level',
                value
              );
            }
          "
        />
        <Dropdown
          v-if="currentArrays.level?.length > 0"
          header="Уровень"
          placeholder="Выберите из списка"
          :disabled="!formData.criterion"
          :keys="
            this.currentArrays.level?.map((item) => {
              return item.title;
            })
          "
          :items="
            this.currentArrays.level?.map((item) => {
              return item.id;
            })
          "
          v-model="formData.level"
          :error="v$.formData.level?.$errors?.length !== 0"
          @update:modelValue="
            (value) => {
              setModelValue(this.formData?.level, 'level', 'status', value);
            }
          "
        />
        <Dropdown
          v-if="currentArrays.status?.length > 0"
          header="Статус"
          placeholder="Выберите из списка"
          :disabled="!formData.level"
          :keys="
            this.currentArrays.status?.map((item) => {
              return item.title;
            })
          "
          :items="
            this.currentArrays.status?.map((item) => {
              return item.id;
            })
          "
          v-model="formData.status"
          :error="v$.formData.status?.$errors?.length !== 0"
        />
        <Input
          header="Год"
          placeholder="Год"
          disabled
          v-model="formData.year"
        />
        <div
          class="social-active-achievements-add__points"
          v-if="lastSelectedFiled"
        >
          <p>
            Количество баллов за достижение:
            <b>{{ lastSelectedFiled?.points }}</b>
          </p>
        </div>

        <Button
          small
          class="social-active-achievements-add__body__btn"
          @click="saveAchievement"
          :loading="isBtnLoading"
        >
          Сохранить
        </Button>
      </div>
      <div class="social-active-achievements-add__body__img">
        <Skeleton v-if="isPictureLoading" />
        <img
          :src="
            !image ? `${require('@/assets/empty-img.svg')}` : imgSrcWithHash
          "
          alt=""
          v-else
        />
      </div>
    </div>
  </div>
</template>

<script>
import Dropdown from "@/components/Blocks/Dropdown";
import Button from "@/components/Blocks/Button";
import Input from "@/components/Blocks/Input";
import { getImg, parseChildrenTrees } from "@/utils/helpers";
import { mapGetters, mapState } from "vuex";
import { createRequest } from "@/api/requestBuilder";
import requestConfigs from "@/api/requestConfigs";
import useVuelidate from "@vuelidate/core";
import validations from "@/enums/validations";
import trayValidationsTexts from "@/enums/trayValidationsTexts";
import { helpers, required } from "@vuelidate/validators";
import moment from "moment";
import Skeleton from "@/components/Blocks/Skeleton";
import PortfolioForm from "@/components/PortfolioSections/PortfolioForm.vue";

export default {
  name: "SocialActiveAchievementAdd",
  components: { PortfolioForm, Skeleton, Input, Dropdown, Button },
  setup() {
    return {
      v$: useVuelidate({ $lazy: true }),
    };
  },
  data() {
    return {
      image: "",
      isPictureLoading: false,
      isBtnLoading: false,
      currentId: "",
      typeAddition: "",
      currentArrays: {
        activityField: [],
        criterion: [],
        level: [],
        status: [],
      },
      formData: {
        achievement: "",
        activityField: "",
        criterion: "",
        level: "",
        status: "",
        year: "",
      },
      portfolioDataForPush: {},
      portfolioData: {},
      portfolioResponse: {},
    };
  },
  props: [
    "achievementsList",
    "currentStatementId",
    "allFields",
    "statementYear",
  ],
  watch: {
    "formData.achievement"() {
      if (this.currentAchievement?.url_original) {
        if (this.hashMedia) {
          this.isPictureLoading = true;
          getImg(this.currentAchievement?.url_original)
            .then((result) => {
              this.image = result;
            })
            .finally(() => {
              this.isPictureLoading = false;
            });
        } else this.image = this.currentAchievement?.url_original;
      }
    },
    formattedAchievementsList(value) {
      this.typeAddition = value && value.length > 0 ? "" : "new";
    },
  },
  validations() {
    let filteredValidationsField = Object.keys(this.formData).filter((item) => {
      return item !== "year" && item !== "achievement";
    });

    let validationRules = validations.achievementsData;

    filteredValidationsField.forEach((item) => {
      if (!this.formData[item]) {
        if (this.currentId) {
          validationRules[item] = {};
        } else {
          validationRules[item] = {
            required: helpers.withMessage("Обязательно к заполнению", required),
          };
        }
      }
    });
    return {
      formData: validationRules,
    };
  },
  computed: {
    imgSrcWithHash() {
      return this.hashMedia
        ? `data:image/png;base64,${this.image}`
        : this.image;
    },
    formattedAchievementsList() {
      return this.achievementsList
        .map((item) => {
          item.title_dropdown = `${item.title} (${item.year} г.)`;
          return item;
        })
        .sort((a, b) => {
          return b.year - a.year;
        });
    },
    fieldsInfo() {
      return trayValidationsTexts.achievementsData;
    },
    currentAchievement() {
      return this.achievementsList?.find((item) => {
        return item.id === this.formData.achievement;
      });
    },
    currentProject() {
      return Object.values(this.projectSettings).find((item) => {
        return item.id === 1;
      });
    },
    lastSelectedFiled() {
      return this.allFields.find((item) => {
        return this.currentId
          ? item.id === this.currentId
          : item.id === this.formData?.status;
      });
    },
    ...mapGetters(["projectSettings", "hashMedia"]),
    ...mapState([
      "achievementItem",
      "currentAchievementId",
      "storage",
      "achievementsItemForPush",
    ]),
  },
  methods: {
    clearFormData() {
      Object.keys(this.formData).forEach((key) => {
        this.formData[key] = "";
      });
    },
    filedIsFinish(id) {
      return this.allFields.find((item) => {
        return item.id === id;
      }).is_finish;
    },
    findParentList(id, nextNameField) {
      this.currentArrays[nextNameField] = this.allFields.filter((item) => {
        return item.parent_id === id;
      });
    },
    setCurrentId(formDataId) {
      if (this.filedIsFinish(formDataId)) {
        this.currentId = formDataId;
      } else this.currentId = "";
    },
    clearData(currentNameField, nextNameField) {
      let values = Object.keys(this.formData).filter((item) => {
        return item !== "year" && item !== "achievement";
      });

      let currentValueIndex = values.findIndex((e) => {
        return e === currentNameField;
      });

      let clearValuesArr = values.filter((item, index) => {
        if (index > currentValueIndex) {
          return item[index];
        }
      });
      clearValuesArr.forEach((item) => {
        this.formData[item] = "";
      });

      let filteredClearValuesArr = clearValuesArr.filter((item) => {
        return item !== nextNameField;
      });
      filteredClearValuesArr.forEach((i) => {
        if (this.currentArrays[i]?.length > 0) {
          this.currentArrays[i] = [];
        }
      });
    },
    setModelValue(id, currentNameField, nextNameField, formDataId) {
      this.findParentList(id, nextNameField);
      this.setCurrentId(formDataId);
      this.clearData(currentNameField, nextNameField);
    },
    async savePortfolio() {
      let payloadObject = {};
      Object.keys(this.portfolioData).forEach((item) => {
        return (payloadObject[item] = this.portfolioData[item]);
      });

      await createRequest(requestConfigs.POSTSavePortfolio, {
        jsonPayload: payloadObject,
      })
        .then((response) => {
          this.portfolioResponse = response.data;
          this.$store.dispatch("pushToPortfolioList", response.data);
        })
        .then(() => {
          this.portfolioDataForPush = {};
          Object.keys(this.portfolioData).forEach((item) => {
            return (this.portfolioData[item] = "");
          });
          this.clearFile = false;
        })
        .then(() => {
          this.$store.commit("pushToTray", {
            text: "Данные успешно сохранены",
            type: "success",
          });
        })
        .catch((error) => {
          this.$store.commit("pushToTray", {
            text: error.errors[0].error_descr,
            type: "error",
          });
        })
        .finally(() => {
          this.isBtnLoading = false;
        });
    },
    async saveAchievement() {
      if (this.typeAddition === "new") {
        const isPortfolioFormFilled =
          Object.values(this.portfolioData)?.length > 0
            ? Object.values(this.portfolioData).every((item) => {
                return !!item;
              })
            : false;
        if (!isPortfolioFormFilled) {
          this.$store.commit("pushToTray", {
            text: "Необходимо заполнить данные о портфолио",
            type: "error",
          });
          return;
        }
      }

      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) {
        this.$store.dispatch("validationErrors", {
          errors: this.v$.$errors,
          fieldsInfo: this.fieldsInfo,
        });
        return;
      }
      this.isBtnLoading = true;
      this.$store.commit("setAchievementItem", this.formData);
      this.$store.commit("setCurrentAchievementId", this.currentId);

      if (this.typeAddition === "new") await this.savePortfolio();

      await this.$store.commit("setAchievementItemForPush", {
        created_at: moment(),
        id: this.currentAchievement?.id ?? this.portfolioResponse?.id,
        title: this.currentAchievement?.title ?? this.portfolioResponse?.title,
        url_original:
          this.currentAchievement?.url_original ??
          this.portfolioResponse?.url_original,
        url_thumb:
          this.currentAchievement?.url_thumb ??
          this.portfolioResponse?.url_thumb,
        image: this.image,
        year: this.currentAchievement?.year ?? this.portfolioResponse?.year,
        project_tree_id: this.formData.status
          ? this.formData.status
          : this.currentId,
        project_tree_title: this.lastSelectedFiled?.title,
        project_tree_points: this.lastSelectedFiled?.points,
        comment_expert: null,
        status: 0,
      });
      await createRequest(requestConfigs.POSTAddDocument, {
        jsonPayload: {
          media_id: this.portfolioResponse?.id ?? this.formData.achievement,
          project_tree_id: this.currentId
            ? this.currentId
            : this.formData.status,
        },
        routerPayload: { id: this.currentStatementId },
      })
        .then(() => {
          this.$store.commit("pushToTray", {
            text: "Документ успешно добавлен",
            type: "success",
          });
          this.$store.commit("setAchievementItem", {});
          this.$store.commit("setCurrentAchievementId", "");
          this.$store.dispatch(
            "pushToAchievementsList",
            this.achievementsItemForPush
          );
        })
        .then(() => {
          this.$store.commit("setAchievementItemForPush", {});
          this.$store.commit("setDocument", ["", "image"]);
        })
        .catch((error) => {
          this.$store.commit("pushToTray", {
            text: error.errors[0].error_descr,
            type: "error",
          });
        })
        .finally(() => {
          this.isBtnLoading = false;
        });
      this.$emit(
        "save_achievement",
        this.formData.status ? this.formData.status : this.currentId
      );
    },
    deleteAchievement() {
      Object.keys(this.formData).forEach((item) => {
        return (this.formData[item] = "");
      });
      Object.keys(this.currentArrays).forEach((item) => {
        return (this.currentArrays[item] = "");
      });
      this.$emit("delete_achievement");
    },
    setAchievement() {
      this.formData.year = this.currentAchievement?.year;
      this.findParentList(0, "activityField");
    },
    setTypeAddition(value) {
      this.typeAddition = value;
      this.image = "";
      this.clearFormData();
    },
    setPortfolioData(data, formData) {
      this.portfolioDataForPush = data;
      this.image = data.push_image;
      this.portfolioData = formData;
      this.formData.year = this.portfolioDataForPush?.year;
      if (this.typeAddition === "new") this.findParentList(0, "activityField");
    },
    parseChildrenTrees,
  },
  mounted() {
    if (Object.values(this.achievementItem).length > 0) {
      this.formData = this.achievementItem;
      this.findParentList(0, "activityField");
      this.findParentList(this.formData?.activityField, "criterion");
      this.findParentList(this.formData?.criterion, "level");
      this.findParentList(this.formData?.level, "status");
    }

    if (this.currentAchievementId) {
      this.currentId = this.currentAchievementId;
    }
    this.typeAddition = this.formattedAchievementsList.length > 0 ? "" : "new";
  },
};
</script>

<style lang="scss">
.social-active-achievements-add {
  padding-bottom: 10px;
  border-bottom: 1px solid var(--background--btn--profile--hover);
  margin-bottom: 16px;
  &__toggle {
    color: var(--background--btn--red);
    background: var(--background--application--review);
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 1.9rem;
    cursor: pointer;
    padding: 16px 21px;
    display: flex;
    gap: 13px;
    border-bottom: 1px solid var(--background--btn--profile--hover);
    border-top: 1px solid var(--background--btn--profile--hover);
    margin-bottom: 23px;
    &:before {
      content: "\e90d";
      font-family: icomoon;
      width: 14px;
      height: 14px;
    }
  }

  &__body {
    display: flex;
    gap: 40px;
    margin-bottom: 25px;
    @include adaptive(tablet-big) {
      flex-wrap: wrap;
    }
    @include adaptive(phone) {
      margin-bottom: 10px;
      gap: 15px;
    }
    &__input {
      flex: 0 1 600px;
    }
    &__btn {
      margin-top: 5px;
    }
    &__img {
      position: relative;
      flex: 0 1 500px;
      background: var(--background--disabled);
      border: 1px solid var(--background--btn--profile--hover);
      border-radius: 5px;
      & img {
        @include full-absolute();
        object-fit: contain;
        border-radius: 5px;
      }
      @include adaptive(tablet-big) {
        order: -1;
        flex: 0 1 300px;
        min-height: 300px;
      }
      @include adaptive(phone) {
      }
    }
  }
  &__description {
    font-size: 1.6rem;
    line-height: 1.9rem;
    margin-bottom: 10px;
    font-weight: 500;
    a {
      color: #4f46e5;
      text-decoration: underline;
    }
  }
  &__btns {
    display: flex;
    grid-gap: 10px;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 10px;
  }
}
</style>
